.fr.sidebar{
	$ACTIVE_COLOR: rgba(100, 140, 227, 0.209);
	.sidebar{
		position: fixed;
		width: 250px;
		height: calc(100% - 40px);
		background-color: white;
		border-right: 1px solid #DDD;
		margin-top: -20px;
		.item{
			padding: 12px 15px;
			margin: 5px;
			border-radius: 8px;
			color: #303030;
			cursor: pointer;
			display: block;
			text-decoration: none;
			font-size: 16px;
			&.header{
				font-weight: bold;
				text-align: center;
				border-bottom: 1px solid #DDD;
				border-radius: 0;
				color: #151717;
				font-size: 20px
			}
			&:hover{
				font-weight: bold;
				color: black;
			}
			&:not(.header):hover, &.active{
				background-color: $ACTIVE_COLOR;
				font-weight: bold;
			}

			i{
				margin-right: 10px;
				width: 20px;
				font-size: 18px;
				text-align: center;
			}
		}
	}
	.contents{
		margin-left: 250px;
		padding: 10px;
		padding-top: 0;
	}
	.overlay{
		position: fixed;
		top: 40px;
		height: 100vh;
		width: 100vw;
		background-color: rgba($color: black, $alpha: 0.4);
	}
	.drawer{
		position: absolute;
		top: 40px;
		background-color: white;
		border-bottom: 1px solid darken(white, 20%);
		width: 100%;
		z-index: 99;
		> * {
			padding: 10px 15px;
		}
		.header{
			padding: 12px 15px;
			cursor: pointer;
			display: flex;
			font-size: 20px;
			font-weight: bold;
			justify-content: space-between;
		}
		.item{
			display: none;
			font-size: 16px;
			color: black;
			padding: 10px 10px;
			margin: 5px 10px;
			border-radius: 8px;
			&.active{
				font-weight: bold;
				background-color: $ACTIVE_COLOR;
			}
		}
		&.active{
			box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
			.header{
				border-bottom: 3px solid darken(white, 10%);
			}
			.item{
				display: block;
			}
		}
	}

	&.mobile{
		.contents{
			margin-left: 0;
			margin-top: 35px;
		}
	}

	@media screen and (max-width: 800px) {
		.sidebar{
			width: 200px;
			.item{
				padding: 10px 12px;
				font-size: 14px;
				i{
					font-size: 16px;
				}
			}
		}
		.contents{
			margin-left: 200px;
		}
	}
	// @media screen and (max-width: 675px){
	// 	.sidebar{
	// 		position: relative;
	// 		width: 100%;
	// 		&:not(.active){
	// 			.item:not(.active){
	// 				display: none;
	// 			}
	// 		}
	// 	}
	// 	.contents{
	// 		margin-left: 0;
	// 	}
	// }
}