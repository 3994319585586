$NAVBAR_COLOR: white;

body{
	background-color: #f5f5f5;
	font-family: Roboto;
}

.ar.navbar{
	width: 100%;
	background-color: $NAVBAR_COLOR;
	display: flex;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	position: fixed;
	justify-content: left;
	top: 0;
	z-index: 100;

	.right {
		margin-left: auto;
	 }

	.left{
		.hamburger.item{
			min-width: 0;
			padding: 0;
			font-size: 26px;
			width: 40px;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover{
				background-color: transparent;
			}
		}
		.logo.item{
			height: 23px;
			padding: 0;
			font-weight: bold;
			font-size: 20px;
			&:hover{
				background-color: transparent;
			}
			margin-right: 10px;
		}
	}
	> *{
		display: flex;
		align-items: center;
		margin: 0 10px;
	}
	* > .item{
		display: flex;
		align-items: center;
		padding: 10px 15px;
		color: #151717;
		text-decoration: none;
		&.active{
			background-color: lighten($NAVBAR_COLOR, 5);
		}
		&.icon{
			width: 40px;
			padding: 0;
			text-align: center;
			justify-content: center;
			i.icon{
				margin: 0;
			}
		}
		&.dropdown{
			.menu{
				min-width: 100%; 
				border-top-left-radius: 0; 
				border-top-right-radius: 0;
			}
			i.icon{
				font-size: 16px;
				line-height: 16px;
				margin-left: 5px;
				margin-right: 0;
			}
		}
	}

	* > .item:hover, > .left .icon:hover, > .left .hamburger:hover{
		background-color: darken($NAVBAR_COLOR, 5);
		cursor: pointer;
	}

	@media screen and (min-width: 450px){
		.left .hamburger.item{
			display: none;
		}
		.right .mobile{
			display: none;
		}
	}
	@media screen and (max-width: 450px){
		.right .desktop{
			display: none;
		}
	}
}


.ar.navsidebar{
	position: fixed;
	top: 60px;
	left: 0;
	width: 75%;
	max-width: 300px;
	background-color: #151717;
	height: calc(100vh - 60px);
	overflow-y: scroll;
	padding-top: 5px;
	z-index: 1000;
	.item{
		min-width: 280px;
		font-size: 24px;
		padding: 15px;
		cursor: pointer;
		display: block;
		color: white;
		border-bottom: 1px solid lighten(#151717, 5);
		&.dropdown{
			padding: 0;
			.content{
				display: flex;
				align-items: center;
				.text{
					flex-grow: 100;
					padding: 15px;
				}
				i.dropdown.icon{
					margin-right: 10px;
					padding-left: 10px;
					margin-right: 15px;
				}
			}
			.menu{
				.item{
					display: block;
					background-color: #1e1e24;
					padding: 15px;
					font-size: 18px;
					color: white;
					margin-bottom: 0;
				}
			}
		}
	}
}

.ar.closable{
	position: fixed;
	top: 60px;
	left: 0;
	width: 100%;
	height: calc(100vh - 60px);
	background-color: rgba(0,0,0,0.8);
	z-index: 999;
}

 
 @media screen and (max-width: 768px) {
	table.fr.selectable{
		max-width: 400px;
	}

	table.fr.selectable th.hide-on-mobile {
		display: none;
	}

	table.fr.selectable td.hide-on-mobile {
		display: none;
	}
	table.fr.selectable td.collapsing.hide-on-mobile {
		display: none;
		}
 }